/* 페이지 경로 */
export const PAGE_PATHS = {
  MAIN: '/', // 메인
  DETAIL : '/accomslist', //상세 몸통,
  BOOKINGS : '/booking',  //예약 몸통,
  MYPAGE : '/mypage',  //마이페이지,
  LOGIN : '/login',
  CALLBOOK: '/callback',
  NOTICE : '/notice',
  CALLCENTRE : `/callcentre`,
  SETTING : `/setting`,
  CONTRACT : '/contract',
  ORDER : `/orders`,
  COUPON : `/coupons`,
}